// import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { FileOrLinkResult } from '../data/FileOrLinkFragment'
import { Container } from './Container'
import s from './FileOrLink.module.sass'
import { Link } from './Link'

export type FileOrLinkProps = {
	file: FileOrLinkResult
}

export const FileOrLink: FunctionComponent<FileOrLinkProps> = ({ file }) => {
	return (
		<Container size="normal">
			{file && file.type === 'link' && file.link && (
				<div className={s.LinkWrapper}>
					<Link className={s.LinkTitle} link={file.link} isTargetBlank={file.link.isTargetBlank}>
						{file.title} ↗
					</Link>
				</div>
			)}
			{file && file.type === 'file' && file.file?.url && (
				<div className={s.FileWrapper}>
					<div className={s.LinkTitle}>{file.title}</div>
					<a className={s.FileLinkDownload} href={file.file.url} download={file.file.name}>
						Stáhnout
					</a>
				</div>
			)}
		</Container>
	)
}
