import clsx from 'clsx'
import type { MediumTileLocaleResult } from '../data/MediumTileLocaleFragment'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import s from './MediumTile.module.sass'

export type MediumTileProps = {
	medium: MediumTileLocaleResult
}

export function MediumTile(props: MediumTileProps) {
	return (
		props.medium.link?.url && (
			<Link href={props.medium.link.url} className={clsx(s.Wrapper)}>
				<div className={s.Container}>
					{props.medium.root?.image && (
						<div className={s.Image}>
							<ContemberImage image={props.medium.root.image} layout="fill" objectFit="cover" />
						</div>
					)}
					<h3 className={s.Name}>{props.medium.title}</h3>
				</div>
			</Link>
		)
	)
}
