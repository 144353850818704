import type { MediumResult } from '../data/MediumFragment'
import { MediumTile } from './MediumTile'
import s from './ProjectCategory.module.sass'

export type MediumCategoryProps = {
	mediums: MediumResult[]
}

export function MediumCategory(props: MediumCategoryProps) {
	return (
		<div className={s.Wrapper}>
			{props.mediums.map(
				(medium) => medium.localesByLocale && <MediumTile key={medium.id} medium={medium.localesByLocale} />,
			)}
		</div>
	)
}
