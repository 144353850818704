import { RichTextRenderer } from '@contember/react-client'
import type { PersonLocaleResult } from '../data/PersonLocaleFragment'
import { ContemberImage } from './ContemberImage'
import s from './Person.module.sass'

export type PersonProps = {
	person: PersonLocaleResult
}

export function Person(props: PersonProps) {
	return (
		<div className={s.Wrapper}>
			{props.person.root?.image && (
				<div className={s.Image}>
					<ContemberImage image={props.person.root?.image} layout="fill" objectFit="cover" />
				</div>
			)}
			<div className={s.Name}>{props.person.name}</div>
			{props.person.text && (
				<div>
					<RichTextRenderer source={props.person.text} />
				</div>
			)}
		</div>
	)
}
