import { z } from 'zod'

export function formDataZod<Schema extends z.ZodRawShape>(
	target: { entries: () => [string, unknown][] } | FormData | React.FormEvent | HTMLFormElement,
	schema: z.ZodObject<Schema>,
	append?: Partial<z.input<z.ZodObject<Schema>>>,
) {
	const result: Record<string, unknown> = {}

	const entries: [string, unknown][] =
		'entries' in target
			? target.entries()
			: new FormData('currentTarget' in target ? target.currentTarget : target).entries()

	for (const [key, value] of entries) {
		if (key in schema.shape && schema.shape[key]) {
			const subschema = schema.shape[key]
			const requiredSubschema: z.ZodTypeAny =
				subschema instanceof z.ZodNullable || subschema instanceof z.ZodOptional ? subschema.unwrap() : subschema

			if (requiredSubschema instanceof z.ZodArray) {
				const list = (result[key] || []) as unknown[]
				result[key] = list
				list.push(value)
			} else {
				if (key in result) {
					throw new Error(`Duplicate key '${key}' for non-array schema.`)
				}
				result[key] = value
			}
		}
	}

	return schema.parse({ ...result, ...append })
}
