import Script from 'next/script'
import type { ResultPageLocaleResult } from '../data/ResultPageLocaleFragment'
import { Container } from './Container'
import { PageTitle } from './PageTitle'
import s from './ResultPage.module.sass'

export type ResultPageProps = {
	page: ResultPageLocaleResult
}

export function ResultPage(props: ResultPageProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<PageTitle title={props.page.title} />
				{/* <h1 className={s.Title}>{props.page.title}</h1> */}
				<Script src="https://cse.google.com/cse.js?cx=326a31438cde249d4" strategy="afterInteractive" />
				<div className="gcse-searchresults-only"></div>
			</div>
		</Container>
	)
}
