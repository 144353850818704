import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { TextListResult } from '../data/TextListFragment'
import s from './ContactBox.module.sass'
import { Container } from './Container'

export type ContactBoxProps = {
	contactBox: TextListResult
}

export function ContactBox(props: ContactBoxProps) {
	return (
		<Container size="normal">
			<div className={clsx(s.Wrapper)}>
				{props.contactBox.items.map((item) => (
					<div className={s.Item} key={item.id}>
						{item.title && <div className={s.Title}>{item.title}</div>}
						<div className={s.TextWrapper}>
							{item.text && (
								<div className={s.Text}>
									<RichTextRenderer source={item.text} />
								</div>
							)}
							{item.textSecond && (
								<div className={s.Text}>
									<RichTextRenderer source={item.textSecond} />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</Container>
	)
}
