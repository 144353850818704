import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import s from './NewsPostTile.module.sass'

export type NewsPostTileProps = {
	tile: NewsPostTileLocaleResult
	pageNum?: number
}

export function NewsPostTile(props: NewsPostTileProps) {
	return (
		<>
			{props.tile.link?.url && (
				<Link href={props.tile.link.url} className={s.Wrapper} data-page-num={props.pageNum}>
					<div className={s.WrapperIn}>
						{props.tile.root?.image && (
							<div className={s.Image}>
								<ContemberImage
									image={props.tile.root.image}
									layout="fill"
									objectFit="contain"
									sizes="(min-width: 1420px) 441px, (min-width: 992px) 33w, (min-width: 768px) 50w, 100w"
								/>
							</div>
						)}
						<div className={s.Date}>
							{new Intl.DateTimeFormat('cs', {
								day: 'numeric',
								month: 'numeric',
								year: 'numeric',
							}).format(new Date(props.tile.publishedAt))}
						</div>
						<h3 className={s.Title}>{props.tile.title}</h3>
						{props.tile.leadParagraph && <div className={s.LeadParagraph}>{props.tile.leadParagraph} ↗</div>}
						<div className={s.Categories}>
							{props.tile.root?.categories.map((category) => (
								<div className={s.Category} key={category.id}>
									{category.localesByLocale?.name}
								</div>
							))}
						</div>
					</div>
				</Link>
			)}
		</>
	)
}
