import type { GrantResult } from '../data/GrantFragment'
import s from './GrantCategory.module.sass'
import { GrantTile } from './GrantTile'

export type GrantCategoryProps = {
	grants: GrantResult[]
}

export function GrantCategory(props: GrantCategoryProps) {
	return (
		<div className={s.Wrapper}>
			{props.grants
				.sort(
					(a, b) =>
						new Date(b.localesByLocale?.publishedAt ?? '').getTime() -
						new Date(a.localesByLocale?.publishedAt ?? '').getTime(),
				)
				.map((grant) => grant.localesByLocale && <GrantTile key={grant.id} grant={grant.localesByLocale} />)}
		</div>
	)
}
