import { useState } from 'react'

export function useKeepPaginatedPages<Item>(pageNum: number, items: Item[], groupKey?: string) {
	const [pages, setPages] = useState<{ pageNum: number; items: Item[]; groupKey?: string }[]>(() =>
		[{ groupKey, pageNum, items }].filter((page) => page.pageNum > 0),
	)

	if (pageNum && !pages.some((p) => p.pageNum === pageNum && p.groupKey === groupKey)) {
		setPages((pages) =>
			[...pages.filter((p) => !(p.pageNum === pageNum && p.groupKey === groupKey)), { groupKey, pageNum, items }]
				.filter((page) => page.pageNum > 0 && page.groupKey === groupKey)
				.sort((a, b) => a.pageNum - b.pageNum),
		)
	}

	return pages
}
