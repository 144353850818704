import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { NewsPostCategoryLocaleResult } from '../data/NewsPostCategoryFragment'
import { usePageContext } from '../pages/[[...path]]'
import { Container } from './Container'
import s from './NewsPostCategories.module.sass'

const categoryQueryKey = 'kategorie'

export type NewsPostCategoriesProps = {
	categories: NewsPostCategoryLocaleResult[]
}

export function useRouteCategories() {
	const router = useRouter()

	const currentCategories = String(router.query[categoryQueryKey] ?? '')
		.split(/[ +]/gim)
		.filter(Boolean)

	return currentCategories
}

export function NewsPostCategories(props: NewsPostCategoriesProps) {
	const page = usePageContext()

	const categories = useRouteCategories()

	return (
		<Container size="wide">
			<div className={s.Categories}>
				{page.data.getNewsPostPage?.page?.localesByLocale?.link?.url && (
					<Link className={s.Category} href={page.data.getNewsPostPage.page.localesByLocale.link?.url}>
						Vše
					</Link>
				)}
				{props.categories.map((item) => (
					<Link
						shallow
						href={{
							pathname: page.data.getNewsPostPage?.page?.localesByLocale?.link?.url ?? '',
							query: toggleCategory(categories, item.slug),
						}}
						className={clsx(s.Category, categories.indexOf(item.slug) > -1 && s.active)}
						key={item.id}
					>
						{item.name}
					</Link>
				))}
			</div>
		</Container>
	)
}

function toggleCategory(original: string[], current: string) {
	const index = original.indexOf(current)

	if (index === -1) {
		return { [categoryQueryKey]: [...original, current].join('+') }
	}

	const copy = [...original]
	copy.splice(index, 1)

	if (copy.length === 0) {
		return
	}

	return { [categoryQueryKey]: copy.join('+') }
}
