import type { ImageProps } from 'next/image'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './ResponsiveImage.module.sass'

export type ResponsiveImageProps = ImageProps & {
	description?: string
}

export const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = (props) => {
	return (
		<div className={styles.wrapper}>
			<figure>
				{/* eslint-disable-next-line jsx-a11y/alt-text */}
				<Image {...props} />
				<figcaption className={styles.description}>{props.description}</figcaption>
			</figure>
		</div>
	)
}
