import type { ProjectResult } from '../data/ProjectFragment'
import s from './ProjectCategory.module.sass'
import { ProjectTile } from './ProjectTile'

export type ProjectCategoryProps = {
	projects: ProjectResult[]
}

export function ProjectCategory(props: ProjectCategoryProps) {
	return (
		<div className={s.Wrapper}>
			{props.projects
				.sort(
					(a, b) =>
						new Date(b.localesByLocale?.publishedAt ?? '').getTime() -
						new Date(a.localesByLocale?.publishedAt ?? '').getTime(),
				)
				.map(
					(project) => project.localesByLocale && <ProjectTile key={project.id} project={project.localesByLocale} />,
				)}
		</div>
	)
}
