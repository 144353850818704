import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useMemo, useState } from 'react'
import { z } from 'zod'
import { formDataZod } from '../utilities/formDataZod'
import type { getMediaRatings } from '../utilities/getMediaRatings'
import { ratingsOrder } from '../utilities/ratingGroups'
import { MediaRatingFilters } from './MediaRatingFilters'
import s from './MediaRatingList.module.sass'
import { RatingLabel } from './RatingLabel'

export type MediaRatingListProps = {
	items: Awaited<ReturnType<typeof getMediaRatings>>
}

const formSchema = z.object({
	search: z.string().optional(),
	rating: z.array(z.string()).optional(),
	mediaType: z.array(z.string()).optional(),
	contentType: z.array(z.string()).optional(),
})

export const MediaRatingList: FunctionComponent<MediaRatingListProps> = ({ items }) => {
	const [formData, setFormData] = useState<FormData | null>(null)

	const filteredItems = useMemo(() => {
		if (!formData) {
			return items
		}

		const validatedFormData = formDataZod(formData, formSchema)

		return formData
			? items.filter((item) => {
					if (validatedFormData.search) {
						if (
							item.type === 'site' &&
							!item.site.name.toLowerCase().includes(validatedFormData.search.toLowerCase())
						) {
							return false
						} else if (
							item.type === 'newspaper' &&
							!item.newspaper.name.toLowerCase().includes(validatedFormData.search.toLowerCase())
						) {
							return false
						}
					}

					if (validatedFormData.mediaType && validatedFormData.mediaType.length > 0) {
						if (
							item.type === 'site' &&
							(!item.site.media_type || !validatedFormData.mediaType.includes(item.site.media_type))
						) {
							return false
						} else if (
							item.type === 'newspaper' &&
							(!item.newspaper.media_type || !validatedFormData.mediaType.includes(item.newspaper.media_type))
						) {
							return false
						}
					}

					if (validatedFormData.contentType && validatedFormData.contentType.length > 0) {
						if (
							item.type === 'site' &&
							(!item.site.content_type || !validatedFormData.contentType.includes(item.site.content_type))
						) {
							return false
						} else if (
							item.type === 'newspaper' &&
							(!item.newspaper.content_type || !validatedFormData.contentType.includes(item.newspaper.content_type))
						) {
							return false
						}
					}

					if (validatedFormData.rating && validatedFormData.rating.length > 0) {
						const hasAtLeastOneMatchingRating = validatedFormData.rating.some((rating) => {
							if (item.type === 'site') {
								return item.site.overall_rate === rating
							} else if (item.type === 'newspaper') {
								return item.newspaper.overall_rate === rating
							}
							return false
						})

						if (!hasAtLeastOneMatchingRating) {
							return false
						}
					}

					return true
			  })
			: items
	}, [formData, items])

	const sortedItems = useMemo(() => {
		return filteredItems.sort((itemA, itemB) => {
			const nameA = itemA.type === 'newspaper' ? itemA.newspaper.name : itemA.site.name
			const nameB = itemB.type === 'newspaper' ? itemB.newspaper.name : itemB.site.name
			const ratingA = itemA.type === 'newspaper' ? itemA.newspaper.overall_rate : itemA.site.overall_rate
			const ratingB = itemB.type === 'newspaper' ? itemB.newspaper.overall_rate : itemB.site.overall_rate

			if (ratingsOrder[ratingA] !== ratingsOrder[ratingB]) {
				return ratingsOrder[ratingA] - ratingsOrder[ratingB]
			}

			return nameA.localeCompare(nameB)
		})
	}, [filteredItems])

	return (
		<>
			<MediaRatingFilters
				onChange={(event) => {
					const formData = new FormData(event.currentTarget)
					setFormData(formData)
				}}
			/>
			<div className={s.List}>
				{sortedItems.map((item, index) =>
					item.type === 'site' ? (
						<Rating
							key={`${item.slug}-${index}`}
							slug={item.slug}
							image={item.site.screenshot}
							name={item.site.name}
							rating={item.site.overall_rate}
							url={item.site.url}
							mediaType={item.site.media_type}
						/>
					) : (
						<Rating
							key={`${item.slug}-${index}`}
							slug={item.slug}
							image={item.newspaper.screenshot}
							name={item.newspaper.name}
							rating={item.newspaper.overall_rate}
							mediaType={item.newspaper.media_type}
						/>
					),
				)}
			</div>
		</>
	)
}

const Rating: FunctionComponent<{
	name?: string
	slug: string
	image?: string
	rating?: string
	url?: string
	mediaType?: string
}> = ({ slug, image, name, rating, url, mediaType = 'other' }) => {
	return (
		<div className={s.Item}>
			<Link href={`/rating/${mediaType}/${slug}`} className={s.Link} />
			<div className={s.ImageWrapper}>
				{image && <Image src={image} alt={name ?? ''} width={216} height={121} className={s.Image} />}
				{rating && (
					<div className={s.Rating}>
						<RatingLabel rating={rating} />
					</div>
				)}
			</div>
			<div className={s.Content}>
				<h3 className={s.TileTitle}>{name}</h3>
				{url && (
					<Link href={`https://${url}`} className={s.Site}>
						{url}
					</Link>
				)}
			</div>
		</div>
	)
}
