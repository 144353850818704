import type { FunctionComponent } from 'react'
import s from './InputGroup.module.sass'

export type InputGroupProps = {
	label?: string
	children: React.ReactNode
}

export const InputGroup: FunctionComponent<InputGroupProps> = ({ children, label }) => {
	return (
		<div className={s.Wrapper}>
			{label && <div className={s.Label}>{label}</div>}
			{children}
		</div>
	)
}
