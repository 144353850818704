import clsx from 'clsx'
import s from './LoadingSpinner.module.sass'

export function LoadingSpinner() {
	return (
		<span className={s.Wrapper}>
			<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={s.Svg}>
				<circle className={s.Dot} cx="4" cy="12" r="3" fill="currentColor" />
				<circle className={clsx(s.Dot, s.a)} cx="12" cy="12" r="3" fill="currentColor" />
				<circle className={clsx(s.Dot, s.b)} cx="20" cy="12" r="3" fill="currentColor" />
			</svg>
		</span>
	)
}
