import type { CSSProperties } from 'react'
import { useMemo } from 'react'
import type { NewsPostsType } from '../../generated/contember/zeus'
import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { Container } from './Container'
import { Link } from './Link'
import { NewsPostTile } from './NewsPostTile'
import s from './NewsPosts.module.sass'

export type NewsPostsProps = {
	title?: string
	columnCount?: number
	linkLabel?: string
	selectedPosts: NewsPostTileLocaleResult[]
	type: NewsPostsType
}

export function NewsPosts(props: NewsPostsProps) {
	const page = usePageContext()

	const posts = useMemo(() => {
		if (props.type === 'current') {
			return page.genericPage?.newsPosts?.posts ?? []
		}
		return props.selectedPosts
	}, [page.genericPage?.newsPosts?.posts, props.selectedPosts, props.type])

	return (
		<Container size="wide">
			<div className={s.TitleWrapper}>
				<h3 className={s.Title}>{props.title || 'Doporučené'}</h3>
				{page.data.getNewsPostPage?.page?.localesByLocale?.link?.url && (
					<Link className={s.Link} href={page.data.getNewsPostPage?.page?.localesByLocale?.link?.url}>
						{props.linkLabel || 'Vidět vše'}
					</Link>
				)}
			</div>
			<div
				className={s.NewsPosts}
				style={{ '--Newspost-columnCount': Math.min(props.columnCount ?? 3, posts.length) } as CSSProperties}
			>
				{posts.map((newsPost) => (
					<div key={newsPost.id} className={s.NewsPost}>
						<NewsPostTile tile={newsPost} />
					</div>
				))}
			</div>
		</Container>
	)
}
