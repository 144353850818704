import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useEffect } from 'react'
import type { CarouselResult } from '../data/CarouselFragment'
import s from './Carousel.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import { useCarousel } from './useCarousel'

export type CarouselProps = {
	carousel: CarouselResult
}

export function Carousel(props: CarouselProps) {
	const { cardsElement, scrollToIndex, activeIndex } = useCarousel(s.Slide)

	const length = props.carousel.items.length

	useEffect(() => {
		const timeout = setTimeout(() => {
			scrollToIndex((activeIndex + 1) % length)
		}, 5000)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeIndex, scrollToIndex, length])

	return (
		<Container size="wide">
			<div ref={cardsElement} className={s.Slides}>
				{props.carousel.items.map((item) => {
					return (
						<div className={s.Slide} key={item.id}>
							{item.image && (
								<div className={s.Image}>
									<ContemberImage image={item.image} layout="fill" objectFit="cover" />
									<div className={s.Indexes}>
										{props.carousel.items.map((item, i) => (
											<button
												onClick={() => scrollToIndex(i)}
												key={item.id}
												className={clsx(s.Index, i === activeIndex && s.isActive)}
											>
												{i + 1}
											</button>
										))}
									</div>
								</div>
							)}
							{item.link ? (
								<Link link={item.link} className={clsx(s.Content, s.ContentLink)}>
									<div className={s.Description}>{item.description}</div>
									{item.text && (
										<div className={s.Text}>
											<RichTextRenderer source={item.text} />
										</div>
									)}
								</Link>
							) : (
								<div className={s.Content}>
									<div className={s.Description}>{item.description}</div>
									{item.text && (
										<div className={s.Text}>
											<RichTextRenderer source={item.text} />
										</div>
									)}
								</div>
							)}
						</div>
					)
				})}
			</div>
		</Container>
	)
}
