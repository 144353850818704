import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import s from './Checkbox.module.sass'

export type CheckboxProps = {
	label: string
	labelPosition?: 'inside' | 'inline'
	onChange?: (checked: boolean) => void
	withOutline?: boolean
	name: string
	checkedValue: string
	checked?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
	label,
	onChange,
	name,
	checkedValue,
	checked,
	labelPosition = 'inline',
}) => {
	return (
		<label className={clsx(s.Wrapper, s[`view_labelPosition_${labelPosition}`])}>
			<input
				type="checkbox"
				className={s.Input}
				onChange={(event) => {
					if (onChange) {
						onChange(event.currentTarget.checked)
					}
				}}
				name={name}
				value={checkedValue}
				checked={checked}
			/>
			<span className={s.Check} />
			<div className={s.Label}>{label}</div>
		</label>
	)
}
