import { Container } from './Container'
import s from './Hr.module.sass'

export type HrProps = {
	text?: string
}

export function Hr(props: HrProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<span className={s.Text}>{props.text}</span>
				<span className={s.Line}></span>
			</div>
		</Container>
	)
}
