import type { FunctionComponent } from 'react'
import { ratingGroups } from '../utilities/ratingGroups'
import s from './RatingLabel.module.sass'

export const RatingLabel: FunctionComponent<{ rating: string }> = ({ rating }) => {
	const value = ratingGroups.flatMap((ratingGroup) => ratingGroup.values).find((value) => value.value === rating)

	return (
		<span className={s.Label} style={{ '--RatingLabel-color': value?.color }}>
			{rating}
		</span>
	)
}
