import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useState } from 'react'
import type { SupportUsResult } from '../data/SupportUsFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import s from './SupportUs.module.sass'

export type SupportUsProps = {
	supportUs: SupportUsResult
}

export function SupportUs(props: SupportUsProps) {
	const [showFirstColumn, setshowFirstColumn] = useState(false)
	const [showSecondColumn, setshowSecondColumn] = useState(false)

	return (
		<Container size="medium">
			<div className={s.Wrapper}>
				<button
					onClick={() => (setshowFirstColumn(true), setshowSecondColumn(false))}
					className={clsx(s.Column, showFirstColumn && s.isActive)}
				>
					<div>{props.supportUs.firstColumnTitle}</div>
					<div className={s.Description}>{props.supportUs.firstColumnDescription}</div>
				</button>
				<button
					onClick={() => (setshowSecondColumn(true), setshowFirstColumn(false))}
					className={clsx(s.Column, showSecondColumn && s.isActive)}
				>
					<div>{props.supportUs.secondColumnTitle}</div>
					<div className={s.Description}>{props.supportUs.secondColumnDescription}</div>
				</button>
				<Link link={props.supportUs.thirdColumn} className={s.Column} />
			</div>
			<div className={clsx(s.Content, showFirstColumn && s.showContent)}>
				{props.supportUs.firstColumnText && <RichTextRenderer source={props.supportUs.firstColumnText} />}
				{props.supportUs.secondColumnImage && (
					<div className={s.Image}>
						<ContemberImage image={props.supportUs.secondColumnImage} />
					</div>
				)}
			</div>
			<div className={clsx(s.Content, showSecondColumn && s.showContent)}>
				{props.supportUs.secondColumnText && <RichTextRenderer source={props.supportUs.secondColumnText} />}
				{props.supportUs.firstColumnImage && (
					<div className={s.Image}>
						<ContemberImage image={props.supportUs.firstColumnImage} />
					</div>
				)}
			</div>
		</Container>
	)
}
