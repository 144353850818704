import Link from 'next/link'
import type { PaginationInfo } from '../utilities/createPagination'
import { useIsNavigating } from '../utilities/useIsNavigating'
import { LoadingSpinner } from './LoadingSpinner'
import s from './Pagination.module.sass'

export function Pagination(props: {
	pagination: PaginationInfo
	visibleCount?: number
	next?: boolean
	prev?: boolean
	loadedRange?: number[]
	onPrev?: React.MouseEventHandler<HTMLAnchorElement>
	onNext?: React.MouseEventHandler<HTMLAnchorElement>
}) {
	const loading = useIsNavigating()

	const currentPageMin = props.loadedRange ? Math.min(...props.loadedRange) : props.pagination.currentPage
	const currentPageMax = props.loadedRange ? Math.max(...props.loadedRange) : props.pagination.currentPage

	const prevUrl =
		currentPageMin > 1 ? props.pagination.urlTemplate.replace('{pageNum}', String(currentPageMin - 1)) : null

	const nextUrl =
		currentPageMax < props.pagination.totalPages
			? props.pagination.urlTemplate.replace('{pageNum}', String(currentPageMax + 1))
			: null

	const next = props.next && nextUrl
	const prev = props.prev && prevUrl

	if (!next && !prev) {
		return null
	}

	return (
		<div className={s.Container}>
			{props.visibleCount && (
				<div className={s.Info}>
					Zobrazuje se {props.visibleCount} z {props.pagination.total}
				</div>
			)}
			<div className={s.Actions}>
				{prev && prevUrl && (
					<Link href={prevUrl} onClick={props.onPrev} scroll={false} className={s.Button}>
						{loading && <LoadingSpinner />} Načíst předchozí
					</Link>
				)}
				{next && nextUrl && (
					<Link href={nextUrl} onClick={props.onNext} scroll={false} className={s.Button}>
						{loading && <LoadingSpinner />} Načíst další
					</Link>
				)}
			</div>
		</div>
	)
}
