import clsx from 'clsx'
import type { GrantLocaleResult } from '../data/GrantLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './Grant.module.sass'

export type GrantProps = {
	grant: GrantLocaleResult
}

export function Grant(props: GrantProps) {
	return (
		<div className={clsx(s.Wrapper)}>
			<Container size="wide">
				<div className={s.Title}>{props.grant.title}</div>
			</Container>
			{props.grant.content && (
				<div className={s.Content}>
					<ContentRenderer content={props.grant.content} />
				</div>
			)}
		</div>
	)
}
