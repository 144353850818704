import clsx from 'clsx'
import Head from 'next/head'
import { usePageContext } from '../pages/[[...path]]'
import { useShare } from '../utilities/useShare'
import { Icon } from './Icon'
import { Link } from './Link'
import s from './ShareIcons.module.sass'

// export type ShareIconsProps = {
// 	genericPage: GenericPageLocaleResult

// }

export function ShareIcons() {
	const share = useShare()

	const page = usePageContext()

	//for local testing
	// const page = (process.env.NEXT_PUBLIC_WEB_URL ?? '') + (props.genericPage.link?.url ?? '')
	// const shareUrl = encodeURIComponent(page)

	const shareUrl = encodeURIComponent(page.seo.canonicalUrl ?? '')
	return (
		<>
			<Head>
				<meta property="title" content={page.seo.seo.title} />
				<meta property="title" content={page.seo.seo.title} />
				<meta name="description" content={page.seo.seo.description} />
				<meta property="og:title" content={page.seo.seo.ogTitle} />
				<meta property="og:description" content={page.seo.seo.ogDescription} />
				{page.seo.seo.ogImage && (
					<>
						<meta property="og:image" content={page.seo.seo.ogImage.url} />
						<meta property="og:image:width" content={`${page.seo.seo.ogImage.width ?? 1600}`} />
						<meta property="og:image:height" content={`${page.seo.seo.ogImage.height ?? 900}`} />
					</>
				)}
			</Head>

			{share !== null ? (
				<div className={s.ShareIcon}>
					<button
						className={s.ShareBtn}
						type="button"
						onClick={() => {
							share?.({
								title: document.title,
								url: window.location.href,
							})
						}}
					>
						<Icon name="share" />
					</button>
				</div>
			) : (
				<div className={s.ShareIcons}>
					<div className={clsx(s.ShareIcon)}>
						<Link className={s.ShareBtn} href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}>
							<Icon name="facebook" />
						</Link>
					</div>
					<div className={clsx(s.ShareIcon)}>
						<Link className={s.ShareBtn} href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}>
							<Icon name="linkedin" />
						</Link>
					</div>
					<div className={clsx(s.ShareIcon)}>
						<Link className={s.ShareBtn} href={`https://twitter.com/intent/tweet?text=${shareUrl}`}>
							<Icon name="twitter" />
						</Link>
					</div>
					<div className={clsx(s.ShareIcon)}>
						<Link className={s.ShareBtn} href={`mailto:?body="${shareUrl}"`}>
							<Icon name="email" />
						</Link>
					</div>
				</div>
			)}
		</>
	)
}
