import clsx from 'clsx'
import type { ChangeEvent, FunctionComponent } from 'react'
import { ratingGroups } from '../utilities/ratingGroups'
import { Checkbox } from './Checkbox'
import { InputGroup } from './InputGroup'
import s from './MediaRatingFilters.module.sass'
import { MediaRatingLegend } from './MediaRatingLegend'
import { SearchInput } from './SearchInput'

export type MediaRatingFiltersProps = {
	onChange: (event: ChangeEvent<HTMLFormElement>) => void
}

export const MediaRatingFilters: FunctionComponent<MediaRatingFiltersProps> = ({ onChange }) => {
	const ratingOptions = ratingGroups.flatMap((ratingGroup) => ratingGroup.values.map((value) => value.value))
	const mediaTypeOptions = [
		{ value: 'web', label: 'WEB' },
		{ value: 'print', label: 'PRINT' },
	] as const

	return (
		<div className={s.Wrapper}>
			<div className={s.Legend}>
				<div className={s.BoxLabel}>Hodnocení médií</div>
				<MediaRatingLegend />
			</div>
			<div className={s.FormWrapper}>
				<div className={s.BoxLabel}></div>
				<form
					className={s.Form}
					onSubmit={(event) => {
						event.preventDefault
					}}
					onChange={onChange}
				>
					<div className={s.LeftColumn}>
						<SearchInput />
						<InputGroup label="Hodnocení">
							<div className={s.Options}>
								{ratingOptions.map((ratingOption, index) => (
									<Checkbox
										key={index}
										checkedValue={ratingOption}
										label={ratingOption}
										labelPosition="inside"
										name="rating"
									/>
								))}
							</div>
						</InputGroup>
					</div>
					<InputGroup label="Typ média">
						<div className={clsx(s.Options, s.view_vertical)}>
							{mediaTypeOptions.map((mediaTypeOption, index) => (
								<Checkbox
									key={index}
									checkedValue={`${mediaTypeOption.value}`}
									label={mediaTypeOption.label}
									name="mediaType"
								/>
							))}
						</div>
					</InputGroup>
				</form>
			</div>
		</div>
	)
}
