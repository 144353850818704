import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import type { GrantTileLocaleResult } from '../data/GrantTileLocaleFragment'
import { Container } from './Container'
import s from './GrantPage.module.sass'
import { GrantTile } from './GrantTile'

export type GrantPageProps = {
	page: GenericPageLocaleResult
	grants: GrantTileLocaleResult[]
}

export function GrantPage(props: GrantPageProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<div className={s.Grants}>{props.grants.map((post) => post && <GrantTile grant={post} key={post.id} />)}</div>
			</div>
		</Container>
	)
}
