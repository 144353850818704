import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useEffect } from 'react'
import type { ReviewResult } from '../data/ReviewFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './Reviews.module.sass'
import { useCarousel } from './useCarousel'

export type ReviewsProps = {
	reviews: ReviewResult[]
}

export function Reviews(props: ReviewsProps) {
	const {
		cardsElement,
		showPreviousButton,
		showNextButton,
		scrollToNext,
		scrollToPrevious,
		scrollToIndex,
		activeIndex,
	} = useCarousel(s.Slide)

	const length = props.reviews.length

	useEffect(() => {
		const timeout = setTimeout(() => {
			scrollToIndex((activeIndex + 1) % length)
		}, 8000)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeIndex, scrollToIndex, length])

	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<div className={s.Title}>Proč jsem v Nadačním fondu nezávislé žurnalistiky?</div>
				<div className={s.Reviews}>
					<div className={clsx(s.ArrowWrapperPrevious)}>
						<button
							type="button"
							className={clsx(s.Arrow, s.Previous, showPreviousButton && s.isVisible)}
							onClick={() => scrollToPrevious()}
							aria-label="previous"
						>
							<Icon name="arrow" />
						</button>
					</div>
					<div ref={cardsElement} className={s.Slides}>
						{props.reviews.map((item) => (
							<div className={s.Slide} key={item.id}>
								{item.image && (
									<div className={s.Image}>
										<ContemberImage image={item.image} layout="fill" objectFit="contain" />
									</div>
								)}
								<div className={s.Content}>
									{item.review && (
										<div className={s.Review}>
											“<RichTextRenderer source={item.review} />”
										</div>
									)}
									<div className={s.ReviewLine} />
									<div className={s.Name}>{item.name}</div>
									{item.position && (
										<div className={s.Position}>
											<RichTextRenderer source={item.position} />
										</div>
									)}
								</div>
							</div>
						))}
					</div>
					<div className={s.ArrowWrapper}>
						<button
							type="button"
							className={clsx(s.Arrow, showNextButton && s.isVisible)}
							onClick={() => scrollToNext()}
							aria-label="next"
						>
							<Icon name="arrow" />
						</button>
					</div>
				</div>
			</div>
		</Container>
	)
}
