import clsx from 'clsx'
import type { GrantTileLocaleResult } from '../data/GrantTileLocaleFragment'
import s from './GrantTile.module.sass'
import { Link } from './Link'

export type GrantTileProps = {
	grant: GrantTileLocaleResult
}

export function GrantTile(props: GrantTileProps) {
	return props.grant.published ? (
		<div className={clsx(s.Wrapper)}>
			<div style={{ backgroundColor: props.grant.root?.category?.theme }} className={s.Container}>
				<div className={s.Type}>Grantová výzva - {props.grant.root?.category?.localesByLocale?.name}</div>
				<div className={s.WrapperIn}>
					<div>
						<div className={s.Name}>{props.grant.title}</div>
						<div className={s.LeadParagraph}>{props.grant.leadParagraph}</div>
						<div className={s.Date}>{props.grant.date}</div>
					</div>
					<Link href={props.grant.link?.url ?? ''} className={s.Button}>
						Více o grantu
					</Link>
				</div>
			</div>
		</div>
	) : null
}
