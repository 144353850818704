import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { ContentResult } from '../data/ContentFragment'
import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import { Breadcrumbs } from './Breadcrumbs'
import { ContentRenderer } from './ContentRenderer'
import s from './GenericPage.module.sass'
import { Link } from './Link'
import { PageTitle } from './PageTitle'
import { ShareIcons } from './ShareIcons'
import { getMediaCategory, type Category } from '../utilities/getMediaCategory'

export type GenericPageProps = {
	genericPage: GenericPageLocaleResult
	parentPages?: Array<{ url: string; title?: string }>
	mediaCategories?: Category[] | null
}

const mediaCategoryNames = await getMediaCategory()

export function GenericPage(props: GenericPageProps) {
	const hasChildren = !!props.genericPage.root?.childPages?.length

	const childPages = hasChildren ? props.genericPage.root?.childPages : props.genericPage.root?.parentPage?.childPages

	const isMediaMapPage = props.genericPage.link?.url === '/mapa-medii'

	return (
		<div className={s.Wrapper}>
			{props.genericPage.hasRedStrip && (
				<div className={s.RedStrip}>
					{props.genericPage.redStripText && <RichTextRenderer source={props.genericPage.redStripText} />}
				</div>
			)}
			{props.parentPages && props.parentPages.length >= 2 && <Breadcrumbs breadcrumb={props.parentPages} />}
			<PageTitle
				title={
					(hasChildren ? props.genericPage.title : props.genericPage.root?.parentPage?.localesByLocale?.title) ??
					props.genericPage.title
				}
			/>
			{childPages && (
				<div className={s.ChildPageLinks}>
					{childPages?.map((childPage) => (
						<Link
							key={childPage.id}
							className={clsx(childPage.id === props.genericPage.root?.id && s.isActive, s.ChildPageLink)}
							href={childPage.localesByLocale?.link?.url ?? '/'}
						>
							{childPage.localesByLocale?.title}
						</Link>
					))}
					{isMediaMapPage &&
						mediaCategoryNames.flatMap((categoryName) => (
							<a href={`/category/${categoryName.slug}`} className={s.CategoryMenuName} key={categoryName.slug}>
								{categoryName.name}
							</a>
						))}
				</div>
			)}

			{isMediaMapPage && (
				<iframe
					src="https://s3.eu-central-1.amazonaws.com/rating.nfnz.cz/mapa_medii_vizualizace/index.html"
					style={{
						width: '100%',
						height: '600px',
						border: 'none',
						marginBottom: '2rem',
					}}
					title="Mapa médií"
				/>
			)}

			{!props.genericPage.hideShareBtns && <ShareIcons />}
			{hasVisibleContent(props.genericPage.content) && <ContentRenderer content={props.genericPage.content} />}
		</div>
	)
}

function hasVisibleContent(content: ContentResult | null | undefined): content is ContentResult {
	if (!content || content.blocks.length === 0) {
		return false
	}
	return !content.blocks.every(
		(b) => b.json === '{"formatVersion":1,"children":[{"type":"paragraph","children":[{"text":""}]}]}',
	)
}
