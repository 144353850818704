import { createContext, useContext, useState } from 'react'
import { Container } from '../components/Container'
import { CookieBar } from '../components/CookieBar'
import { Footer } from '../components/Footer'
import { GenericPage } from '../components/GenericPage'
import { Grant } from '../components/Grant'
import { GrantPage } from '../components/GrantPage'
import { Header } from '../components/Header'
import { MediaRatingList } from '../components/MediaRatingList'
import { Medium } from '../components/Medium'
import { NewsPost } from '../components/NewsPost'
import { NewsPostPage } from '../components/NewsPostPage'
import { Project } from '../components/Project'
import { ProjectPage } from '../components/ProjectPage'
import { ResultPage } from '../components/ResultPage'
import { Seo } from '../components/Seo'
import { CommonPageDataFragment } from '../data/CommonPageDataFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { GrantCategoryLocaleFragment } from '../data/GrantCategoryLocaleFragment'
import { GrantLocaleFragment } from '../data/GrantLocaleFragment'
import { GrantTileLocaleFragment } from '../data/GrantTileLocaleFragment'
import { LinkFragment } from '../data/LinkFragment'
import { MediumCategoryLocaleFragment } from '../data/MediumCategoryLocaleFragment'
import { MediumLocaleFragment } from '../data/MediumLocaleFragment'
import { NewsPostCategoryLocaleFragment } from '../data/NewsPostCategoryFragment'
import { NewsPostLocaleFragment } from '../data/NewsPostLocaleFragment'
import { NewsPostTileLocaleFragment } from '../data/NewsPostTileLocaleFragment'
import { ProjectCategoryLocaleFragment } from '../data/ProjectCategoryLocaleFragment'
import { ProjectLocaleFragment } from '../data/ProjectLocaleFragment'
import { ProjectTileLocaleFragment } from '../data/ProjectTileLocaleFragment'
import { ResultPageLocaleFragment } from '../data/ResultPageLocaleFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { createPagination } from '../utilities/createPagination'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import { getMediaRatings } from '../utilities/getMediaRatings'
import type { InferDataLoaderProps } from '../utilities/handlers'
import { handleGetStaticPaths, handleGetStaticProps } from '../utilities/handlers'
import { getMediaCategory } from '../utilities/getMediaCategory'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function (props: PageProps) {
	const { newsPost, grant, project, genericPage, medium, resultPage } = props

	const [isCookieBarOpen, setIsCookieBarOpen] = useState(false)
	return (
		<PageContext.Provider value={props}>
			<Seo {...props.seo} />
			{props.data.getHeaderLocale && (
				<Header
					header={props.data.getHeaderLocale}
					newsletterModal={props.data.getNewsletterFormLocale}
					parentPages={props.parentPages}
					resultPageUrl={props.data.getResultPage?.localesByLocale?.link?.url}
				/>
			)}
			{newsPost && <NewsPost newsPost={newsPost.page} categories={newsPost.categories.listNewsPostCategoryLocale} />}
			{grant && <Grant grant={grant.page} />}
			{project && <Project project={project.page} />}
			{medium && <Medium medium={medium.page} />}
			{resultPage && <ResultPage page={resultPage} />}
			{genericPage && (
				<GenericPage
					genericPage={genericPage.page}
					parentPages={props.parentPages}
					mediaCategories={genericPage?.mediaCategories}
				/>
			)}
			{genericPage?.grants && <GrantPage page={genericPage.page} grants={genericPage.grants.posts} />}
			{genericPage?.projects && <ProjectPage page={genericPage.page} projects={genericPage?.projects.posts} />}
			{genericPage?.page.root?.newsPostPage && genericPage.newsPosts && (
				<NewsPostPage
					page={genericPage.page}
					categories={genericPage.newsPosts.categories}
					posts={genericPage.newsPosts.posts}
					pagination={genericPage.newsPosts.pagination ?? undefined}
				/>
			)}
			{genericPage?.page.root?.mediaRatingPage && genericPage.mediaRatings && (
				<Container size="wide">
					<MediaRatingList items={genericPage.mediaRatings} />
				</Container>
			)}
			{/*{genericPage?.page.root?.mediaMapPage && genericPage.mediaCategories && (
				<Container size="wide">
					<MediaMapList items={genericPage.mediaCategories} />
				</Container>
			)}*/}
			{props.data.getCookieBarLocale && (
				<CookieBar
					cookieBar={props.data.getCookieBarLocale}
					isCookieBarOpen={isCookieBarOpen}
					setIsCookieBarOpen={setIsCookieBarOpen}
				/>
			)}
			{props.data.getFooterLocale && (
				<Footer
					footer={props.data.getFooterLocale}
					newsletterModal={props.data.getNewsletterFormLocale}
					openCookieBar={() => setIsCookieBarOpen(true)}
					hasCallToDefendMediaBox={props.data.getLinkable?.genericPage?.hasCallToDefendMediaBox}
				/>
			)}
		</PageContext.Provider>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember.query({
		listLinkable: [
			{
				filter: {
					not: {
						or: [
							{ redirect: { id: { isNull: true } } },
							{
								genericPage: { redirectsTo: { id: { isNull: true } } },
							},
						],
					},
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const mediaCategory = await getMediaCategory()

	const linkable = getLinkableUrlFromContext(context)

	const { url, locale } = linkable

	if (typeof locale !== 'string') {
		throw new Error('Invalid locale')
	}

	const data = await contember.query({
		...CommonPageDataFragment(locale),
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				grant: [{}, GrantLocaleFragment(locale)],
				project: [{}, ProjectLocaleFragment(locale)],
				medium: [{}, MediumLocaleFragment(locale)],
				newsPost: [
					{ filter: { hidden: { notEq: true }, publishedAt: { lte: 'now' } } },
					NewsPostLocaleFragment(locale),
				],
				genericPage: [{}, GenericPageLocaleFragment(locale)],
				resultPage: [{}, ResultPageLocaleFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}
	if (data.getLinkable?.genericPage?.redirectsTo) {
		return {
			redirect: {
				permanent: false,
				destination: data.getLinkable?.genericPage?.redirectsTo.url,
			},
		}
	}
	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		const canonical = `${process.env.NEXT_PUBLIC_WEB_URL ?? ''}${url}`
		if (linkable.pageNum) {
			return `${canonical}/${linkable.pageNum}`
		}
		return canonical
	})()

	const parentPages: Array<{ url: string; title?: string }> = []

	const genericPage = await (async () => {
		const initial = data.getLinkable?.genericPage ?? null

		if (!initial) {
			return null
		}

		const mediaCategories = initial.link?.url.includes('mapa-medii') ? await getMediaCategory() : null

		// should also take into account parent of parent
		if (data.getLinkable?.genericPage?.root?.parentPage?.parentPage?.localesByLocale?.link?.url) {
			parentPages.push({
				url: data.getLinkable.genericPage.root.parentPage.parentPage.localesByLocale.link.url,
				title: data.getLinkable.genericPage.root.parentPage.parentPage.localesByLocale.title,
			})
		}
		if (data.getLinkable?.genericPage?.root?.parentPage?.localesByLocale?.link?.url) {
			parentPages.push({
				url: data.getLinkable.genericPage.root.parentPage.localesByLocale.link.url,
				title: data.getLinkable.genericPage.root.parentPage.localesByLocale.title,
			})
		}
		if (data.getLinkable) {
			parentPages.push({ url: data.getLinkable.url, title: data.getLinkable.genericPage?.title })
		}

		const pageNum = linkable.pageNum ?? 1
		const perPage = Number(process.env.NEWS_POSTS_PER_PAGE ?? 12)

		const newsPostInBlocksCounts = data.getLinkable?.genericPage?.content?.blocks
			.flatMap((b) =>
				b.references.filter((r) => r.type === 'newsPosts' && r.newsPostsType === 'current').map((r) => r.number),
			)
			.filter(Boolean)

		const newsPosts = data.getLinkable?.genericPage?.root?.newsPostPage?.id
			? await (async () => {
					const data = await contember.query({
						__alias: {
							categories: {
								listNewsPostCategoryLocale: [{}, NewsPostCategoryLocaleFragment()],
							},
							posts: {
								paginateNewsPostLocale: [
									{
										first: perPage,
										skip: pageNum * perPage - perPage,
										filter: { hidden: { notEq: true }, publishedAt: { lte: 'now' } },
										orderBy: [
											{
												publishedAt: 'desc',
											},
										],
									},
									{ pageInfo: { totalCount: true }, edges: { node: NewsPostTileLocaleFragment(locale) } },
								],
							},
						},
					})
					const pagination = createPagination({
						baseUrl: url,
						pageNum,
						perPage,
						totalCount: data?.posts.pageInfo.totalCount ?? 0,
					})
					return {
						categories: data?.categories ?? [],
						posts: data?.posts.edges.map((edge) => edge.node) ?? [],
						pagination,
					}
			  })()
			: newsPostInBlocksCounts?.length
			? await (async () => {
					const data = await contember.query({
						listNewsPostLocale: [
							{
								filter: { hidden: { notEq: true }, publishedAt: { lte: 'now' } },
								limit: Math.max(...newsPostInBlocksCounts),
								orderBy: [
									{
										publishedAt: 'desc',
									},
								],
							},
							NewsPostTileLocaleFragment(locale),
						],
					})
					return {
						categories: [],
						posts: data.listNewsPostLocale ?? [],
						pagination: null,
					}
			  })()
			: null

		const grants = data.getLinkable?.genericPage?.root?.grantPage?.id
			? await contember.query({
					__alias: {
						categories: { listGrantCategoryLocale: [{}, GrantCategoryLocaleFragment()] },
						posts: { listGrantLocale: [{ orderBy: [{ publishedAt: 'desc' }] }, GrantTileLocaleFragment(locale)] },
					},
			  })
			: null

		const projects = data.getLinkable?.genericPage?.root?.projectPage?.id
			? await contember.query({
					__alias: {
						categories: { listProjectCategoryLocale: [{}, ProjectCategoryLocaleFragment()] },
						posts: { listProjectLocale: [{ orderBy: [{ publishedAt: 'desc' }] }, ProjectTileLocaleFragment(locale)] },
					},
			  })
			: null

		const mediaRatings = data.getLinkable?.genericPage?.root?.mediaRatingPage?.id ? await getMediaRatings() : null

		return {
			page: initial,
			newsPosts,
			grants,
			mediaCategories,
			projects,
			mediaRatings,
		}
	})()

	const resultPage = data.getLinkable?.resultPage

	const newsPost = await (async () => {
		const initial = data.getLinkable?.newsPost ?? null

		if (!initial) {
			return null
		}

		if (data.getNewsPostPage?.page?.localesByLocale?.link?.url) {
			parentPages.push({
				url: data.getNewsPostPage?.page?.localesByLocale?.link?.url,
				title: data.getNewsPostPage?.page?.localesByLocale?.title,
			})
		}

		const categories = await contember.query({
			listNewsPostCategoryLocale: [{}, NewsPostCategoryLocaleFragment()],
		})

		return {
			page: initial,
			categories,
		}
	})()

	const grant = await (async () => {
		const initial = data.getLinkable?.grant ?? null

		if (!initial) {
			return null
		}

		if (data.getGrantPage?.page?.localesByLocale?.link?.url) {
			parentPages.push({
				url: data.getGrantPage?.page?.localesByLocale?.link?.url,
				title: data.getGrantPage?.page?.localesByLocale?.title,
			})
		}

		const categories = await contember.query({
			listGrantCategoryLocale: [{}, GrantCategoryLocaleFragment()],
		})

		return {
			page: initial,
			categories: categories,
		}
	})()

	const project = await (async () => {
		const initial = data.getLinkable?.project ?? null

		if (!initial) {
			return null
		}

		if (data.getProjectPage?.page?.localesByLocale?.link?.url) {
			parentPages.push({
				url: data.getProjectPage?.page?.localesByLocale?.link?.url,
				title: data.getProjectPage?.page?.localesByLocale?.title,
			})
		}

		const categories = await contember.query({
			listProjectCategoryLocale: [{}, ProjectCategoryLocaleFragment()],
		})

		return {
			page: initial,
			categories: categories,
		}
	})()

	const medium = await (async () => {
		const initial = data.getLinkable?.medium ?? null

		if (!initial) {
			return null
		}

		const categories = await contember.query({
			listMediumCategoryLocale: [{}, MediumCategoryLocaleFragment()],
		})

		return {
			page: initial,
			categories: categories,
		}
	})()

	if (!genericPage && !newsPost && !grant && !project && !medium && !resultPage) {
		return {
			notFound: true,
			revalidate: 60,
		}
	}

	const seo =
		genericPage?.page.seo ??
		newsPost?.page.seo ??
		grant?.page.seo ??
		project?.page.seo ??
		medium?.page.seo ??
		data.getGeneral?.seo ??
		{}

	return {
		props: {
			data,
			mediaCategory,
			genericPage,
			parentPages,
			newsPost,
			grant,
			project,
			medium,
			resultPage,
			seo: {
				canonicalUrl,
				seo,
			},
		},
		revalidate: 60,
	}
})

export const PageContext = createContext<null | PageProps>(null)

export function usePageContext() {
	const context = useContext(PageContext)
	if (!context) {
		throw new Error('Missing PageContext')
	}
	return context
}
