import type { FunctionComponent } from 'react'
import { ratingGroups } from '../utilities/ratingGroups'
import s from './MediaRatingLegend.module.sass'

export const MediaRatingLegend: FunctionComponent = () => {
	return (
		<div className={s.Wrapper}>
			{ratingGroups.map((ratingGroup, index) => (
				<div key={index}>
					<div className={s.Values}>
						{ratingGroup.values.map((value) => (
							<div key={value.value} className={s.Value} style={{ '--MediaRatingLegend-value-color': value.color }}>
								{value.value}
							</div>
						))}
					</div>
					<div className={s.Label}>{ratingGroup.label}</div>
				</div>
			))}
		</div>
	)
}
