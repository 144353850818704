import clsx from 'clsx'
import type { ProjectLocaleResult } from '../data/ProjectLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './Project.module.sass'

export type ProjectProps = {
	project: ProjectLocaleResult
}

export function Project(props: ProjectProps) {
	return (
		<div className={clsx(s.Wrapper)}>
			<Container size="wide">
				<div className={s.Title}>{props.project.title}</div>
			</Container>
			{props.project.content && (
				<div className={s.Content}>
					<ContentRenderer content={props.project.content} />
				</div>
			)}
		</div>
	)
}
