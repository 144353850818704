import { RichTextRenderer } from '@contember/react-client'
import { useState } from 'react'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { CallToDefendMediaFormLocaleResult } from '../data/CallToDefendMediaFormFragment'
import { api } from '../utilities/api'
import s from './CallToDefendMediaForm.module.sass'
import { Container } from './Container'
import { FlashMessage } from './FlashMessage'
import { Link } from './Link'

export type CallToDefendMediaFormProps = {
	callToDefendMediaForm: CallToDefendMediaFormLocaleResult
}

export function CallToDefendMediaForm(props: CallToDefendMediaFormProps) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [city, setCity] = useState('')
	const [consentToPublish, setConsentToPublish] = useState(false)

	const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>(undefined)

	const [formState, setFormState] = useState<'initial' | 'success' | 'error'>('initial')

	const mutation = api.callToDefendMedia.send.useMutation({
		onSuccess(data) {
			if (!data.ok) {
				setFormErrorMessage(data.errorMessage)
				setFormState('error')
			} else {
				setFormState('success')
			}
		},
		onError: () => {
			alert('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.')
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<Container size="normal">
			{formState === 'initial' ? (
				<form
					className={s.Wrapper}
					onSubmit={(event) => {
						event.preventDefault()
						mutation.mutate({
							firstName,
							lastName,
							email,
							city,
							consentToPublish,
						})
					}}
				>
					<div className={s.FormFields}>
						<label className={s.FormField}>
							{props.callToDefendMediaForm.firstName} *
							<input
								className={s.Input}
								type="text"
								value={firstName}
								onChange={(event) => {
									setFirstName(event.target.value)
								}}
								required
								autoComplete="given-name"
							/>
						</label>
						<label className={s.FormField}>
							{props.callToDefendMediaForm.lastName} *
							<input
								className={s.Input}
								type="text"
								value={lastName}
								onChange={(event) => {
									setLastName(event.target.value)
								}}
								required
								autoComplete="family-name"
							/>
						</label>
					</div>
					<div className={s.FormFields}>
						<label className={s.FormField}>
							{props.callToDefendMediaForm.email} *
							<input
								className={s.Input}
								type="email"
								autoComplete="email"
								value={email}
								onChange={(event) => {
									setEmail(event.target.value)
								}}
								required
							/>
						</label>
						<label className={s.FormField}>
							{props.callToDefendMediaForm.city}
							<input
								className={s.Input}
								type="text"
								value={city}
								onChange={(event) => {
									setCity(event.target.value)
								}}
								autoComplete="address-level2"
							/>
						</label>
					</div>

					<label className={s.CheckboxWrapper}>
						<input
							type="checkbox"
							checked={consentToPublish}
							onChange={(event) => {
								setConsentToPublish(event.currentTarget.checked)
							}}
						/>
						{props.callToDefendMediaForm.consentToPublish}
					</label>

					{props.callToDefendMediaForm.text && (
						<div className={s.Text}>
							<RichTextRenderer source={props.callToDefendMediaForm.text} />
						</div>
					)}

					<button type="submit" className={s.Button}>
						{props.callToDefendMediaForm.submitBtn}
					</button>
				</form>
			) : formState === 'success' ? (
				<FlashMessage type="success">
					Těší nás, že jsou pro vás nezávislá média důležitá a rozhodli jste se připojit k Výzvě NFNZ. Váš podpis je
					nutné potvrdit kliknutím na odkaz, který jsme vám zaslali e-mailem, děkujeme. (E-mail byste již nyní měli mít
					ve své e-mailové schránce. Pokud ho tam nevidíte, zkontrolujte prosím, zda ho nemáte v promoakcích nebo ve
					spamu.)
				</FlashMessage>
			) : (
				<FlashMessage type="neutral">
					{formErrorMessage ?? 'Něco se při odesílání pokazilo. Zkuste to znovu prosím později.'}
				</FlashMessage>
			)}
			<Link className={s.ListOfSupporters} link={props.callToDefendMediaForm.listOfSupportersBtn} />
		</Container>
	)
}
