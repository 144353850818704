import clsx from 'clsx'
import type { MediumLocaleResult } from '../data/MediumLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './Medium.module.sass'
import { PageTitle } from './PageTitle'

export type MediumProps = {
	medium: MediumLocaleResult
}

export function Medium(props: MediumProps) {
	return (
		<div className={clsx(s.Wrapper)}>
			<PageTitle title={props.medium.title} />
			{props.medium.root?.image && (
				<Container>
					<div className={s.Image}>
						<ContemberImage image={props.medium.root.image} />
					</div>
				</Container>
			)}
			{props.medium.content && (
				<div className={s.Content}>
					<ContentRenderer content={props.medium.content} />
				</div>
			)}
		</div>
	)
}
