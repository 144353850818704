import type { NewsPostCategoryLocaleResult } from '../data/NewsPostCategoryFragment'
import type { NewsPostLocaleResult } from '../data/NewsPostLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import { Icon } from './Icon'
import { Link } from './Link'
import s from './NewsPost.module.sass'
import { NewsPostCategories } from './NewsPostCategories'
import { NewsPosts } from './NewsPosts'
import { ShareIcons } from './ShareIcons'

export type NewsPostProps = {
	newsPost: NewsPostLocaleResult
	categories?: NewsPostCategoryLocaleResult[]
}

export function NewsPost(props: NewsPostProps) {
	const page = usePageContext()

	const pickedNewsPosts = props.newsPost.root?.relatedNewsPosts?.items
		.map((item) => item.newsPost?.localesByLocale)
		.filter(Boolean)

	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				{props.categories && <NewsPostCategories categories={props.categories} />}
				<Container size="normal">
					{props.newsPost.root?.image && (
						<div className={s.Image}>
							<ContemberImage image={props.newsPost.root.image} />
						</div>
					)}
					<div className={s.ImageCaptionWrapper}>
						{page.data.getNewsPostPage?.page?.localesByLocale?.link?.url && (
							<Link href={page.data.getNewsPostPage?.page?.localesByLocale?.link?.url} className={s.BackButton}>
								<span className={s.BackIcon}>
									<Icon name="arrow" />
								</span>
								<span className={s.BackLabel}>Zpět</span>
							</Link>
						)}
						<div className={s.ImageCaption}>{props.newsPost.imageCaption}</div>
					</div>
					<div className={s.ShareIcons}>
						<ShareIcons />
					</div>
				</Container>
				<div className={s.Content}>
					<Container size="wide">
						<div className={s.InfoWrapper}>
							<div className={s.PublishedAt}>
								{new Intl.DateTimeFormat('cs', {
									day: 'numeric',
									month: 'numeric',
									year: 'numeric',
								}).format(new Date(props.newsPost.publishedAt))}
							</div>
							{props.newsPost.root?.categories.map((category) => (
								<div className={s.Category} key={category.id}>
									{category.localesByLocale?.name}
								</div>
							))}
						</div>
						<h1 className={s.Title}>{props.newsPost.title}</h1>
						<div className={s.TitleBorder} />
						<h2 className={s.LeadParagraph}>{props.newsPost.leadParagraph}</h2>
					</Container>
					{props.newsPost.content && (
						<div className={s.Content}>
							<ContentRenderer content={props.newsPost.content} />
						</div>
					)}
				</div>
				<div className={s.NewsPosts}>
					<NewsPosts
						selectedPosts={pickedNewsPosts?.length ? pickedNewsPosts : page.data.listNewsPostLocale}
						columnCount={4}
						type="selected"
					/>
				</div>
			</div>
		</Container>
	)
}
