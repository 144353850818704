import type { FunctionComponent } from 'react'
import { Icon } from './Icon'
import s from './SearchInput.module.sass'

export const SearchInput: FunctionComponent = () => {
	return (
		<div className={s.Wrapper}>
			<span className={s.Icon}>
				<Icon name="search" />
			</span>
			<input className={s.Input} name="search" type="search" placeholder="Najdi" />
		</div>
	)
}
