import { useEffect, useId } from 'react'
import { z } from 'zod'
import { Container } from './Container'
import s from './Embed.module.sass'

export type EmbedProps = {
	embed: string
}

const postmessageLink = z.object({
	message: z.literal('link'),
	secret: z.string(),
})

const postmessageHeight = z.object({
	message: z.literal('height'),
	value: z.number(),
})

const postmessage = z.union([postmessageLink, postmessageHeight])

export function Embed(props: EmbedProps) {
	const secret = useIframeHelpers(props.embed)

	return (
		<Container size="normal">
			<iframe
				className={s.Wrapper}
				src={`${props.embed}embed/#?secret=${secret}`}
				security="restricted"
				sandbox="allow-scripts"
				scrolling="no"
				data-secret={secret}
			></iframe>
		</Container>
	)
}

function useIframeHelpers(url: string) {
	const secret = encodeURIComponent(useId())

	useEffect(() => {
		const callback = (e: MessageEvent) => {
			const data = postmessage.safeParse(e.data)
			if (data.success) {
				switch (data.data.message) {
					case 'link':
						window.location.href = url
						return
					case 'height': {
						const iframeEl = document.querySelector<HTMLIFrameElement>(`[data-secret="${secret}"]`)
						if (iframeEl) {
							iframeEl.style.height = `${data.data.value}px`
						}
						return
					}
					default:
						console.log('Unhandled postmessage', e)
				}
			}
		}
		window.addEventListener('message', callback)

		return () => {
			window.removeEventListener('message', callback)
		}
	}, [url, secret])

	return secret
}
