import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/contember/zeus'
import type { ContentBlockResult } from '../data/ContentBlockFragment'
import type { ContentResult } from '../data/ContentFragment'
import { usePageContext } from '../pages/[[...path]]'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { CallToDefendMediaForm } from './CallToDefendMediaForm'
import { Carousel } from './Carousel'
import { ContactBox } from './ContactBox'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { Embed } from './Embed'
import { FileOrLink } from './FileOrLink'
import { Gallery } from './Gallery'
import { GrantCategory } from './GrantCategory'
import { Hr } from './Hr'
import { LeadParagraph } from './LeadParagraph'
import { Link } from './Link'
import { Linkables } from './Linkables'
import { MediumCategory } from './MediumCategory'
import { NewsPosts } from './NewsPosts'
import { PersonCategory } from './PersonCategory'
import { ProjectCategory } from './ProjectCategory'
import { ResponsiveImage } from './ResponsiveImage'
import { Reviews } from './Reviews'
import { SignaturesOfCallToDefendMedia } from './SignaturesOfCallToDefendMedia'
import { SpotifyEmbed } from './SpotifyEmbed'
import { SupportUs } from './SupportUs'
import { Wysiwyg } from './Wysiwyg'
import { YoutubeVideo } from './YoutubeVideo'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	image: function image({ reference }) {
		return (
			reference.image && (
				<Container>
					<ResponsiveImage
						src={reference.image.url}
						width={reference.image.width}
						height={reference.image.height}
						alt={reference.image.alt ?? ''}
						description={reference.primaryText}
					/>
				</Container>
			)
		)
	},
	gallery: function gallery({ reference }) {
		return (
			reference.gallery && (
				<Container>
					<Gallery gallery={reference.gallery} />
				</Container>
			)
		)
	},
	personCategory: function personCategory({ reference }) {
		return (
			reference.personCategory && (
				<Container size="wide">
					<PersonCategory people={reference.personCategory.people} />
				</Container>
			)
		)
	},
	grantCategory: function grantCategory({ reference }) {
		return (
			reference.grantCategory && (
				<Container size="wide">
					<GrantCategory grants={reference.grantCategory.grants} />
				</Container>
			)
		)
	},
	projectCategory: function projectCategory({ reference }) {
		return (
			reference.projectCategory && (
				<Container size="wide">
					<ProjectCategory projects={reference.projectCategory?.projects} />
				</Container>
			)
		)
	},
	mediumCategory: function mediumCategory({ reference }) {
		return (
			reference.mediumCategory && (
				<Container size="wide">
					<MediumCategory mediums={reference.mediumCategory?.mediums} />
				</Container>
			)
		)
	},
	contactBox: function contactBox({ reference }) {
		return (
			reference.textList && (
				<Container size="wide">
					<ContactBox contactBox={reference.textList} />
				</Container>
			)
		)
	},
	reviews: function ReviewsBlock() {
		const page = usePageContext()
		return (
			<Container size="fullWidth" disableGutters>
				<Reviews reviews={page.data.listReview} />
			</Container>
		)
	},
	carousel: function carousel({ reference }) {
		return (
			reference.carousel && (
				<Container size="fullWidth" disableGutters>
					<Carousel carousel={reference.carousel} />
				</Container>
			)
		)
	},
	leadParagraph: function leadParagraph({ children }) {
		return (
			<Container size="fullWidth" disableGutters>
				<LeadParagraph test={children} />
			</Container>
		)
	},
	youtubeVideo: function youtubeVideo({ reference }) {
		return (
			reference.youtubeVideo && (
				<Container size="fullWidth" disableGutters>
					<YoutubeVideo youtubeVideo={reference.youtubeVideo} />
				</Container>
			)
		)
	},
	fileOrLink: function fileOrLink({ reference }) {
		return (
			reference.fileOrLink && (
				<Container size="fullWidth" disableGutters>
					<FileOrLink file={reference.fileOrLink} />
				</Container>
			)
		)
	},
	embed: function embed({ reference }) {
		return (
			reference.primaryText && (
				<Container size="fullWidth" disableGutters>
					<Embed embed={reference.primaryText} />
				</Container>
			)
		)
	},
	hr: function hr({ reference }) {
		return (
			<Container size="fullWidth" disableGutters>
				<Hr text={reference.primaryText} />
			</Container>
		)
	},
	newsPosts: function newsPosts({ reference }) {
		const selectedPosts = reference.newsPosts.map((item) => item.localesByLocale).filter(Boolean)

		return (
			<Container size="fullWidth" disableGutters>
				<NewsPosts
					selectedPosts={selectedPosts}
					title={reference.primaryText}
					linkLabel={reference.secondaryText}
					columnCount={reference.number}
					type={reference.newsPostsType ?? 'current'}
				/>
			</Container>
		)
	},
	callToDefendMediaForm: function Block() {
		const page = usePageContext()
		return (
			<Container size="fullWidth" disableGutters>
				{page.data.getCallToDefendMediaForm?.localesByLocale && (
					<CallToDefendMediaForm callToDefendMediaForm={page.data.getCallToDefendMediaForm?.localesByLocale} />
				)}
			</Container>
		)
	},
	signaturesOfCallToDefendMedia: function Block() {
		const page = usePageContext()
		return (
			<Container size="fullWidth" disableGutters>
				{page.data.listCallToDefendMediaSubmission && (
					<SignaturesOfCallToDefendMedia signatures={page.data.listCallToDefendMediaSubmission} />
				)}
			</Container>
		)
	},
	supportUs: function supportUs({ reference }) {
		return (
			<Container size="fullWidth" disableGutters>
				{reference.supportUs && <SupportUs supportUs={reference.supportUs} />}
			</Container>
		)
	},
	linkables: function linkables({ reference }) {
		return (
			<Container>
				<Linkables title={reference.primaryText} items={reference.linkables} />
			</Container>
		)
	},
	spotifyEmbed: function spotifyEmbed({ reference }) {
		return (
			reference.primaryText && (
				<Container>
					<SpotifyEmbed spotifyEmbed={reference.primaryText} />
				</Container>
			)
		)
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={
					// @TODO use useCallback to prevent unnecessary rerenders
					(element) => {
						const type = element.element.type as (typeof element)['element']['type'] | ContentReferenceType

						const reference = element.reference as ContentBlockResult['references'][number]

						if (type === 'link') {
							return (
								<Link link={reference.target} isTargetBlank={reference.target?.isTargetBlank}>
									{element.children}
								</Link>
							)
						}

						if (type === 'table') {
							return (
								<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
									<Container>{element.fallback}</Container>
								</div>
							)
						}

						if (nestedTypes.includes(type)) {
							return element.fallback
						}

						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										element.referenceType && styles[`is_reference_${element.referenceType}`],
									)}
								>
									{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
										element.fallback
									) : (
										<Container disableGutters={containerDisableGutters}>
											<div className={styles.notImplemented}>
												<div className={styles.notImplemented_name}>{element.referenceType}</div>
												is not yet implemented
											</div>
										</Container>
									)}
								</div>
							)
						}
						return (
							<div className={clsx(styles.section, styles.is_wysiwyg)}>
								<Container disableGutters={containerDisableGutters}>
									<Wysiwyg>{element.fallback}</Wysiwyg>
								</Container>
							</div>
						)
					}
				}
				referenceRenderers={referenceRenderers}
			/>
		</div>
	)
}
