import clsx from 'clsx'
import s from './SpotifyEmbed.module.sass'

export type SpotifyEmbedProps = {
	spotifyEmbed: string
}

export function SpotifyEmbed(props: SpotifyEmbedProps) {
	return (
		<div className={clsx(s.Wrapper)}>
			<div dangerouslySetInnerHTML={{ __html: props.spotifyEmbed }}></div>
		</div>
	)
}
