import type { PersonResult } from '../data/PersonFragment'
import { Person } from './Person'
import s from './PersonCategory.module.sass'

export type PersonCategoryProps = {
	people: PersonResult[]
}

export function PersonCategory(props: PersonCategoryProps) {
	return (
		<div className={s.Wrapper}>
			{props.people.map(
				(person) => person.localesByLocale && <Person key={person.id} person={person.localesByLocale} />,
			)}
		</div>
	)
}
