import slugify from 'slugify'
import { z } from 'zod'

const kriteria = z
	.array(
		z.object({
			nazev_kriteria: z.array(z.string()),
			hodnoceni: z.array(z.string()).optional(),
			popis: z.array(z.string()),
		}),
	)
	.optional()

const mediaMapScheme = z.object({
	mapa_medii: z.array(
		z.object({
			web: z.object({
				detail_o_webu: z.object({
					nazev: z.array(z.string()),
					adresa_webu: z.array(z.string()),
					screenshot: z.array(z.string()),
					kategorie: z.array(z.string()),
					popis: z.array(z.string()),
					datum_posledniho_hodnoceni: z.array(z.string()),
				}),
				kriteria,
			}),
		}),
	),
})

export const createSlug = (text: string) => slugify(text, { lower: true })

export async function getMediaCategory() {
	const categories = [
		{
			type: 'mapa_medii',
			url: 'https://babel-cors-proxy.fly.dev/http://rating.nfnz.cz.s3.amazonaws.com/mapa_medii_json/mapamedii.json',
		},
	] as const

	const data = await Promise.all(
		categories.map(async (category) => {
			const response = await fetch(category.url)
			const json = await response.json()

			return {
				data: mediaMapScheme.parse(json).mapa_medii.map(
					(mapScheme) =>
						({
							name: mapScheme.web.detail_o_webu.kategorie[0],
							slug: slugify(mapScheme.web.detail_o_webu.kategorie[0], { lower: true }),
						} as const),
				),
			} as const
		}),
	)

	const items = data.flatMap((category) => category.data)

	const removeDuplicates = (array: Category[], key: string): Category[] => {
		return array.reduce((arr: Category[], item: Category) => {
			const removed = arr.filter((i) => i[key] !== item[key])
			return [...removed, item]
		}, [])
	}

	const nonDuplicatiedResults = removeDuplicates(items, 'slug')
	return nonDuplicatiedResults
}

export type Category = {
	name: string
	slug: string
}
