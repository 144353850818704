import s from './Breadcrumbs.module.sass'
import { Container } from './Container'
import { Icon } from './Icon'
import { Link } from './Link'

export type BreadcrumbsProps = {
	breadcrumb?: Array<{ url: string; title?: string }>
}

export function Breadcrumbs(props: BreadcrumbsProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				{props.breadcrumb?.map((breadcrumb, index) => (
					<Link key={index} href={breadcrumb.url} className={s.Breadcrumb}>
						{breadcrumb.title}
						<span className={s.Icon}>
							<Icon name="arrow" />
						</span>
					</Link>
				))}
			</div>
		</Container>
	)
}
