import type { YoutubeVideoResult } from '../data/YoutubeVideoFragment'
import { Container } from './Container'
import s from './YoutubeVideo.module.sass'

export type YoutubeVideoProps = {
	youtubeVideo: YoutubeVideoResult
}

export function YoutubeVideo(props: YoutubeVideoProps) {
	return (
		<Container size="normal">
			<iframe
				className={s.Video}
				id="ytplayer"
				width="100%"
				height="auto"
				src={`https://www.youtube.com/embed/${props.youtubeVideo.videoId}`}
			></iframe>
		</Container>
	)
}
