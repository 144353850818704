import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import type { NewsPostCategoryLocaleResult } from '../data/NewsPostCategoryFragment'
import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { api } from '../utilities/api'
import type { PaginationInfo } from '../utilities/createPagination'
import { useKeepPaginatedPages } from '../utilities/useKeepPaginatedPages'
import { Container } from './Container'
import { NewsPostCategories, useRouteCategories } from './NewsPostCategories'
import s from './NewsPostPage.module.sass'
import { NewsPostTile } from './NewsPostTile'
import { Pagination } from './Pagination'

export type NewsPostPageProps = {
	page: GenericPageLocaleResult
	categories: NewsPostCategoryLocaleResult[]
	posts: NewsPostTileLocaleResult[]
	pagination?: PaginationInfo
}

export function NewsPostPage(props: NewsPostPageProps) {
	const categories = useRouteCategories()

	if (categories.length) {
		return <NewsPostPageCategories {...props} filter={{ categories }} />
	}

	return <NewsPostList {...props} />
}

function NewsPostList(
	props: NewsPostPageProps & {
		onPrev?: React.MouseEventHandler<HTMLAnchorElement>
		onNext?: React.MouseEventHandler<HTMLAnchorElement>
		groupKey?: string
	},
) {
	const currentPage = props.pagination?.currentPage ?? 0
	const [initialPageNum] = useState(currentPage)

	const pageNum = currentPage

	const pages = useKeepPaginatedPages(currentPage, props.posts, props.groupKey)

	const posts = useMemo(() => {
		return pages.flatMap((page) => page.items)
	}, [pages])

	const range = pages.map((p) => p.pageNum)

	useEffect(() => {
		if (initialPageNum !== pageNum) {
			const el = document.querySelector<HTMLAnchorElement>(`[data-page-num="${pageNum}"]`)
			if (el && 'scrollIntoView' in el) {
				el.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}
	}, [initialPageNum, pageNum])

	return (
		<Container size="wide">
			{props.pagination && (
				<Pagination
					onPrev={props.onPrev}
					onNext={props.onNext}
					loadedRange={range}
					pagination={props.pagination}
					prev
				/>
			)}
			<div className={s.Wrapper}>
				<NewsPostCategories categories={props.categories} />
				<div className={s.NewsPosts}>
					{pages.flatMap((page) =>
						page.items.map((post) => <NewsPostTile pageNum={page.pageNum} tile={post} key={post.id} />),
					)}
				</div>
			</div>
			<div className={s.Pagination}>
				{props.pagination && (
					<Pagination
						onPrev={props.onPrev}
						onNext={props.onNext}
						loadedRange={range}
						pagination={props.pagination}
						next
						visibleCount={posts.length}
					/>
				)}
			</div>
		</Container>
	)
}

function NewsPostPageCategories(props: NewsPostPageProps & { filter: { categories: string[] } }) {
	const { filter, ...rest } = props

	const router = useRouter()

	const groupKey = JSON.stringify(props.filter ?? null)

	const [pageNum, setPageNum] = useState({ groupKey, pageNum: 1 })

	if (pageNum.groupKey !== groupKey) {
		setPageNum({ groupKey, pageNum: 1 })
	}

	const posts = api.posts.filter.useQuery(
		{
			pathname: props.page.link?.url ?? '',
			page: pageNum.pageNum,
			filter: props.filter,
			locale: router.locale ?? 'cs',
		},
		{
			keepPreviousData: true,
		},
	)

	return (
		<>
			<NewsPostList
				{...rest}
				groupKey={JSON.stringify(posts.data?.filter ?? null)}
				pagination={posts.data?.pagination}
				posts={posts.data?.posts ?? []}
				onNext={(e) => {
					e.preventDefault()
					setPageNum((prev) => ({
						...prev,
						pageNum: Math.min(posts.data?.pagination.totalPages ?? 1, prev.pageNum + 1),
					}))
				}}
				onPrev={(e) => {
					e.preventDefault()
					setPageNum((prev) => ({ ...prev, pageNum: Math.max(1, prev.pageNum - 1) }))
				}}
			/>
		</>
	)
}
