import clsx from 'clsx'
import type { ProjectTileLocaleResult } from '../data/ProjectTileLocaleFragment'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import s from './ProjectTile.module.sass'

export type ProjectTileProps = {
	project: ProjectTileLocaleResult
}

export function ProjectTile(props: ProjectTileProps) {
	return props.project.published ? (
		<>
			{props.project.link?.url && (
				<Link href={props.project.link.url} className={clsx(s.Wrapper)}>
					<div className={s.Container}>
						{props.project.root?.image && (
							<div className={s.Image}>
								<ContemberImage image={props.project.root.image} layout="fill" objectFit="cover" />
							</div>
						)}
						<h3 className={s.Name}>{props.project.title}</h3>
						<div className={s.LeadParagraph}>{props.project.leadParagraph}</div>
						<div className={s.Support}>
							<span className={s.SupportLabel}>Podpora: </span>
							{props.project.support}
						</div>
						<div className={s.Date}>
							<span className={s.DateLabel}>Období podpory: </span>
							{props.project.date}
						</div>
					</div>
				</Link>
			)}
		</>
	) : null
}
