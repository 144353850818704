import type { CallToDefendMediaSubmissionResult } from '../data/CallToDefendMediaSubmissionFragment'
import { Container } from './Container'
import s from './SignaturesOfCallToDefendMedia.module.sass'

export type SignaturesOfCallToDefendMediaProps = {
	signatures: CallToDefendMediaSubmissionResult[]
}

export function SignaturesOfCallToDefendMedia(props: SignaturesOfCallToDefendMediaProps) {
	return (
		<div className={s.Wrapper}>
			<Container size="normal">
				{props.signatures.map((item) => (
					// <div key={item.id}>
					<div key={item.id} className={s.Signature}>
						{item.firstName} {item.lastName}, {item.city}
					</div>
					// </div>
				))}
			</Container>
		</div>
	)
}
