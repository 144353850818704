export const ratingsOrder = {
	A: 0,
	'A-': 1,
	'B+': 2,
	B: 3,
	'B-': 4,
	C: 5,
	N: 6,
}

export const ratingGroups = [
	{
		values: [
			{ value: 'A', color: '#57CD6B' },
			{ value: 'A-', color: '#B7E15C' },
		],
		label: 'splňuje novinářske standardy',
	},
	{
		values: [
			{ value: 'B+', color: '#E7DF27' },
			{ value: 'B', color: '#FED606' },
			{ value: 'B-', color: '#FE9B06' },
		],
		label: 'převážně splňuje',
	},
	{ values: [{ value: 'C', color: '#FE4206' }], label: 'většinou nebo vůbec nesplňuje novinářske standardy' },
	{ values: [{ value: 'N', color: '#D9D9D9' }], label: 'nezařazeno' },
]
