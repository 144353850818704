import { Container } from './Container'
import s from './LeadParagraph.module.sass'

export type LeadParagraphProps = {
	test: React.ReactNode
}

export function LeadParagraph(props: LeadParagraphProps) {
	return (
		<Container size="normal">
			<div className={s.Wrapper}>{props.test}</div>
		</Container>
	)
}
