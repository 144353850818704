import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import type { ProjectTileLocaleResult } from '../data/ProjectTileLocaleFragment'
import { Container } from './Container'
import s from './ProjectPage.module.sass'
import { ProjectTile } from './ProjectTile'

export type ProjectPageProps = {
	page: GenericPageLocaleResult
	projects: ProjectTileLocaleResult[]
}

export function ProjectPage(props: ProjectPageProps) {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<div className={s.Projects}>
					{props.projects.map((post) => post && <ProjectTile project={post} key={post.id} />)}
				</div>
			</div>
		</Container>
	)
}
