import s from './PageTitle.module.sass'

export type PageTitleProps = {
	title?: string
}

export function PageTitle(props: PageTitleProps) {
	return (
		<>
			<h1 className={s.Title}>{props.title}</h1>
			{props.title && <div className={s.TitleLine} />}
		</>
	)
}
