import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export function useCarousel(slideClassName: string) {
	const cardsElement = useRef<HTMLDivElement>(null)

	const [activeIndex, setActiveIndex] = useState(0)

	const [slides, setSlides] = useState<HTMLDivElement[]>([])

	useEffect(() => {
		const wrapper = cardsElement.current
		if (wrapper === null) {
			return
		}
		setSlides(Array.from(wrapper.querySelectorAll(`.${slideClassName}`)))
	}, [slideClassName])

	const scrollToIndex = useCallback(
		(index: number) => {
			if (!cardsElement.current) {
				return
			}
			if (index < 0 || index >= slides.length) {
				return
			}
			cardsElement.current.scrollTo({
				behavior: 'smooth',
				left: slides[index].offsetLeft - cardsElement.current.offsetLeft,
			})
		},
		[slides],
	)

	const scrollToNext = useCallback(() => {
		scrollToIndex(activeIndex + 1)
	}, [activeIndex, scrollToIndex])

	const scrollToPrevious = useCallback(() => {
		scrollToIndex(activeIndex - 1)
	}, [activeIndex, scrollToIndex])

	const showPreviousButton = useMemo(() => {
		return activeIndex >= 1
	}, [activeIndex])

	const showNextButton = useMemo(() => {
		return activeIndex < slides.length - 1
	}, [activeIndex, slides.length])

	const recalculate = useCallback(() => {
		const distances = slides.map((slide) => {
			const rect = slide.getBoundingClientRect()
			return Math.abs(window.innerWidth / 2 - (rect.left + rect.width / 2))
		})
		let closestIndex = 0
		distances.forEach((distance, index) => {
			if (distance < distances[closestIndex]) {
				closestIndex = index
			}
		})
		setActiveIndex(closestIndex)
	}, [slides])

	useEffect(() => {
		const wrapper = cardsElement.current
		if (wrapper === null) {
			return
		}

		recalculate()

		window.addEventListener('resize', recalculate)
		wrapper.addEventListener('scroll', recalculate)
		return () => {
			window.removeEventListener('resize', recalculate)
			wrapper.addEventListener('scroll', recalculate)
		}
	}, [recalculate])

	return {
		cardsElement,
		showPreviousButton,
		showNextButton,
		scrollToNext,
		scrollToPrevious,
		scrollToIndex,
		activeIndex,
	}
}
